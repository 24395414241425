// Loading indicators

.tn-event-listing-view--loading {
    .tn-event-listing-view__controls-container,
    .tn-event-listing-view__results-container {
        opacity: .65;
    }
}

.tn-event-listing-busy-indicator,
.tn-syos-busy-indicator {
    box-shadow: 0 0 0 100vmax rgba($black, .75);
    background-color: $white;
    
    & &__content {
        padding: $spacer-ml !important;
        color: $black;
        border-radius: $border-radius-base;
        opacity: 1;
        box-shadow: 0 $spacer-m $spacer-l rgba($black, .35);
        border: none;
        @include font-size-base-plus-1;
        font-style: normal;
        gap: $spacer-m;

        &::after,
        .tn-busy-spinner {
            width: $spacer-ml !important;
            height: $spacer-ml !important;
            opacity: 1 !important;
            border-radius: 50%;
            border: 10px solid $blue;
            border-top-color: transparent;
            animation: loader 1s linear infinite;
            margin-top: 0;
            background-image: none !important;
        }

        img, 
        .tn-busy-spinner__image {
            display: none;
        }
    }

    &__message {
        font-style: normal !important;
        margin-bottom: 0 !important;
    }
}

@keyframes loader {
    to {
        transform: rotate(360deg);
    }
}
.tn-gift-certificate-default-page {
    .tn-header-component {
        .btn {
            display: inline-block;
            min-width: 120px;
            text-align: left;
            padding: 10px 30px 10px 10px;
            letter-spacing: 3px;
        }
    }
}
.tn-syos {

    .tn-syos__layout-container {
        background-color: $light-grey;
        border: none;

        @include mq($from: tablet) {
            border-radius: $border-radius-large;
        }
    }

    // Left sidebar
    .tn-syos-screen-container__button-list-heading {
        color: $black;
        font-family: $font-family-copy;
        @include font-size-base;
    }

    .tn-syos-screen-container__button-list-container {
        border-color: $white;
        background-color: $light-grey;
        border-width: 0 2px 0 0;
        padding: $spacer-s;
    }

    .tn-syos-screen-button__section-heading  {
        @include font-size-base-plus-1;
        border-bottom-color: $dark-grey;
    }
    .tn-syos-screen-button {
        @include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-bg-hover);
        border-radius: $border-radius-small;

        &:disabled {
            opacity: .6;
            cursor: not-allowed;
        }
    }

    // Main content
    .tn-syos-screen-container__map-container {
        background-color: transparent;
    }

    .tn-syos-seat-container-viewport {
        background-color: transparent;
    }

    .tn-syos-seat-map__svg {
        background-color: transparent;
    }

    .tn-syos-seat-map__non-seat {
        color: $black;
    }

    .tn-syos-btn-view-screens {
        @include btn-to-link;
        @include cta-reverse;

        .fa {
            display: none;
        }
    }

    // Legend
    .tn-syos-legend__legend-drawer {
        background-color: $light-grey;
        border-left: 2px solid $white;
    }

    .tn-syos-legend__desktop-heading {
      font-size: 1.1em;
    }

    .tn-syos-legend__desktop-heading-wrapper {
        background-color: transparent;
        border-bottom: 1px solid $white;
    }

    .tn-syos-zoom-control__btn,
    .tn-syos-legend__btn-close-legend {
        @include btn;
        @include button-variant($btn-secondary-color, $btn-secondary-bg, $btn-secondary-bg-hover);
    }

    // SYOS FOOTER
    .tn-syos-mobile-cart-controls {
        background-color: $light-grey;
        height: auto;
        padding: $spacer-s;
        border-top: 2px solid $white;
        align-items: center;
        flex-wrap: wrap;

        @include mq($until: tablet) {
            width: 100%;
            text-align: center;
            bottom: 0 !important;
        }
    }

    .tn-syos-mobile-cart-controls__summary {
        background-color: transparent;
        border: none;

        @include mq($until: tablet) {
            text-align: right;
            padding-right: 0;
        }
    }

    .tn-syos-mobile-cart-controls__summary-seat-count,
    .tn-syos-mobile-cart-controls__summary-subtotal {
        color: $black;
        @include heading-treatment;
    }

    .tn-syos__btn-add-to-cart {
        @include btn;
        @include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-bg-hover);
        padding: 20px;

        &:disabled {
            opacity: .55;
            background-color: $btn-primary-bg;
            color: $btn-primary-color;
            border-color: $btn-primary-bg;
        }

        @include mq($until: tablet) {
            width: 100%;
            justify-content: center;
        }
    }

    .tn-syos-legend__btn-show-legend,
    .tn-syos-legend__btn-show-legend-mobile,
    .tn-syos-mobile-cart-controls__btn-view-cart {
        @include btn;
        @include button-variant($btn-secondary-color, $btn-secondary-bg, $btn-secondary-bg-hover);
        @include font-size-base-minus-1;
        @include button-size($padding-small-vertical, $padding-small-horizontal);
        width: auto;
        flex-direction: row;
        gap: $spacer-xs;

        &:disabled {
            opacity: .55;
            background-color: $btn-secondary-bg;
            color: $btn-secondary-color;
            border-color: $btn-secondary-bg;
        }

        .tn-syos-mobile-cart-controls__btn-view-cart-icon {
            font-size: 1em;
            width: auto;
            margin-bottom: 0;
        }

        .tn-syos-mobile-cart-controls__btn-view-cart-label {
            line-height: 1;
            padding: 0;
        }
    }

    .tn-syos-legend__btn-show-legend-mobile {
        bottom: 150px;
        @include mq($from: desktop) {
            display: none;
        }
    }

    .tn-syos-legend__btn-show-legend {
        @include mq($until: desktop) {
            display: none;
        }
    }

    // seat tooltip/popover {
    .tn-syos-seat-info-popover__content-container,
    .tn-syos-price-type-selector__content-container {
        background-color: $white;
        box-shadow: 0 0 $spacer-m $black;
        border-radius: $border-radius-small;
    }

    .tn-syos-seat-info__header,
    .tn-syos-seat-info__header {
        background-color: transparent;
        border-bottom-color: $light-grey;
    }

    .tn-modal__btn-close {
        @include btn;
        @include button-variant($btn-secondary-color, $btn-secondary-bg, $btn-secondary-bg-hover);

        &::before,
        &::after {
            background-color: currentColor;
        }
    }

    // expandable seats selection
    .tn-syos-cart.tn-syos-mobile-popover {
        border: none;
    }

    .tn-syos-cart__content-container {
        background: $white;
        border: none;
    }

    .tn-syos-cart__summary-items {
        margin-bottom: $spacer-m;
    }

    .tn-syos-cart__seats-list {
        background-color: transparent;
        padding: 0;
        box-shadow: none;
    }

    .tn-syos-cart-seat {
        & + .tn-syos-cart-seat {
            border-top: 1px solid $medium-grey;
        }
    }

    .tn-syos-cart-seat__btn-remove {
        @include btn;
        @include button-variant($btn-secondary-color, $btn-secondary-bg, $btn-secondary-bg-hover);
        @include font-size-base-minus-2;
        @include button-size($padding-small-vertical, $padding-small-horizontal);
    }
}

.modal-open {
  .tn-syos {
    .modal-title {
      font-size: 0px;
      &::after {
        content: "Key & Prices";
        font-size: 18px;
      }
    }
  }
}

.twc-digital-wallet {
  .o-skeleton {
    position: relative;
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    opacity: 0.9;
    .row {
      position: relative;
      margin: 0 !important;
      height:180px;
    }
    span {
      animation: pulse-bg 1s infinite;
      display: block;
      width: 20%;
    }
  }

  .o-skeleton--generic {
    h3 {
      margin: 0 0 25px;
    }
    span {
      height: 25px;
    }
    .o-skeleton__seat {
      height: 40px;
      width: 200px;
      margin-bottom: 15px;
    }
    .o-skeleton__price-type {
      height: 18px;
      width: 80px;
      margin-bottom: 5px;
    }
    .o-skeleton__price {
      height: 18px;
      width: 50px;
      margin-bottom: 15px;
    }
    .o-skeleton__apple-wallet-button {
      height: 40px;
      width: 122px;
      border-radius: 7px;
    }
    .o-skeleton__google-wallet-button {
      height: 40px;
      width: 140px;
      border-radius: 25px;
    }
    .o-skeleton__qrcode {
      position: absolute;
      height: 140px;
      width: 140px;
      float: right;
      top: 0;
      right: 0;
    }

    .wrap {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
    }
  }

  @-webkit-keyframes pulse-bg {
    0% {
      background-color: #F2F2F2;
    }
    50% {
      background-color: #FAFAFA;
    }
    100% {
      background-color: #F2F2F2;
    }
  }

  @keyframes pulse-bg {
    0% {
      background-color: #F2F2F2;
    }
    50% {
      background-color: #FAFAFA;
    }
    100% {
      background-color: #F2F2F2;
    }
  }
}

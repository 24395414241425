.twc-account--ov-local-membership {
  .twc-account--ov-local-membership--card {
    width: 100%;
    background-color: $navy;
    color: #FFF;
    padding: 2rem;
    &.member {
      display: block;
      flex-direction: row;
      .twc-card-left {
        flex: 2;
      }
      .twc-card-right {
        flex: 1;
      }
      .ov-local-membership__logo {
        width: 100%;
      }
      .qrcode {
        max-width: 160px;
        padding: 10px;
        background-color: $white;
      }
      @media (min-width: 45em) {
        display: flex;
        h2 {
          margin: 0 -25px;
        }
        button {
          font-size: 1rem;
        }
        .qrcode {
          float: right;
        }
        .ov-local-membership__logo {
          width: 80%;
        }
      }
    }
    &.new {
      display: block;
      flex-direction: row;
      .twc-card-left {
        display: flex;
        flex: 1;
      }
      .twc-card-right {
        flex: 2;
      }
      .ov-local-membership__logo {
        &.mini-logo {
          width: 100%;
        }
      }
      p {
        margin: 1rem 0 0;
        max-width: 400px;
      }
      button {
        margin: 2rem 0 0;
        font-size: .8rem;
      }
      @media (min-width: 45em) {
        display: flex;
        padding: 2rem 0 !important;
        button {
          font-size: 1rem;
        }
        .ov-local-membership__logo {
          &.mini-logo {
            clip-path: inset(0 74% 0 25px);
            margin-left: -25px;
            width: 395%;
          }
        }
      }
    }
    .ov-local-membership__info {
      legend {
        margin-bottom: 1rem;
        border: none;
        font-size: 1.2rem;
        font-weight: bold;
      }
      label {
        margin-right: 1rem;
      }
      input[type="radio"] {
        margin-right: .5rem;
      }
      p {
        margin: 1rem 0 0;
        max-width: 700px;
      }
    }
  }
  .twc-account--ov-local-membership-benefits {
    margin-top: 2rem;
    h4 {
      font-size: 1.2rem;
      text-transform: none;
      letter-spacing: 0;
      font-family: $font-family-copy;
    }
    .ov-local-membership-benefits-list {
      display: flex;
      flex-direction: row;
      list-style: none;
      padding: 0;
      margin: 1rem 0;
      li {
        flex: 1;
        background-color: $gray-lighter;
        padding: .5rem 1rem;
        flex-basis: 40%;
        &:nth-child(1) {
          margin: 0 1.5rem  0 0;
        }
        &::before {
          font-family: "FontAwesome";
          content: "\F058";
          margin-right: .5rem;
          font-size: 1.4rem;
        }
      }
    }
    .terms {
      font-size: 1.2rem;
      text-transform: none;
      letter-spacing: 0;
      font-family: $font-family-copy;
    }
  }
}

.twc-nav-item {
  margin-bottom: 2px !important;
}

.tn-account-login-page {
    .tn-account-login-forgot-login-link {
        @include btn-to-link;
    }

    .tn-register-button {
        @include button-variant($btn-secondary-color, $btn-secondary-bg, $btn-secondary-bg-hover);
    }

    h2 {
        @include font-size-base-plus-3;
    }
}

.tn-account-logout-page {
    .row:last-child {
        .tn-btn-link {
            @include btn-to-link;
            @include cta-reverse;
        }
    }
}
.twc-account--membership-upsell--card {
  width: 100%;
  background-color: $navy;
  color: #FFF;
  padding: 2rem;
  display: block;
  flex-direction: row;
  margin-bottom: 2rem;
  .twc-card-left {
    display: flex;
    flex: auto;
  }
  .twc-card-right {
    flex: 10;
  }
  .ov-membership-upsell__logo {
    &.mini-logo {
      margin-left: 0;
      width: 100%;
    }
  }
  p {
    margin: 1rem 0 0;
    max-width: 400px;
  }
  a {
    margin: 2rem 0 0;
    font-size: .8rem;
  }
  @media (min-width: 45em) {
    display: flex;
    padding: 2rem 0 !important;
    a {
      font-size: 1rem;
    }
    .ov-membership-upsell__logo {
      &.mini-logo {
        clip-path: inset(0 83% 0 25px);
        margin-left: -25px;
        width: 600%;
      }
    }
  }
  .membership-upsell__info {
    margin-bottom: 2rem;
    h3 {
      font-size: 1rem;
    }
  }
  @media (min-width: 45em) {
    .membership-upsell__title {
      font-size: 5rem;
      .text-outlined {
        letter-spacing: -.9375rem;
      }
    }
  }
}

.c-footer {
    background-color: $black;
    color: $white;
    padding: 1.25rem 0;

    @include mq($from:tablet) {
        padding: 3rem 0;
    }

    @include mq($from:desktop) {
        padding: 4rem 0;
    }

    hr {
        margin: 1rem 0;

        @include mq($from:desktop) {
            margin: 1.5rem 0;
        }
    }

    &__container {

    }

    &__row {
        margin-bottom: .75rem;
        margin-top: .75rem;
        align-items: center;
        display: flex;
        justify-content: space-between;
        gap: 1rem;

        &--top {
            align-items: flex-start;
            flex-direction: column-reverse;
            margin-top: 0;

            @include mq($from:tablet) {
                flex-direction: row;
                flex-wrap: nowrap;
            }
        }

        &--bottom {
            @include font-size-base-minus-2;
            margin-bottom: 0;
        }
    }

    &__sponsors {
        display: flex;
        gap: $spacer-ml;
        flex-direction: column;

        @include mq($from:desktop) {
            flex-direction: row;
        }
    }

    &__partners {
        display: flex;
        gap: $spacer-ml;
        flex: 0 1 auto;

        &-column {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        &-header {
            @include font-size-base-minus-1;
            @include heading-treatment;
            margin-bottom: .5rem;
        }
    }

    &__arts-council {
        display: flex;
        flex-direction: column;
        flex: 1 0 auto;

        &-copy {
            @include font-size-base-minus-2;
            margin-top: 1rem;
            display: flex;
            flex-direction: column;
        }
    }

    &__principle-partner {
        flex-grow: 1;
        width: 100%;
        display: flex;

        @include mq($from:tablet) {
            justify-content: flex-end;
        }

        &-header {
            @include font-size-base-minus-1;
            @include heading-treatment;
            display: block;
            margin-bottom: .5rem;
        }
    }

    &__logo {
        fill: $white;
        height: auto;
        display: none;

        @include mq($from:tablet) {
            width: 7.75rem;
            display: block;
        }

        @include mq($from:desktop) {
            width: 8.375rem;
        }
    }

    &__info {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        &-row {
            display: flex;
            gap: .5rem;
            flex-direction: column;

            @include mq($from:tablet) {
                gap: 1.5rem;
                justify-content: flex-end;
                flex-direction: row;
            }

            span {
                display: block
            }

            h3 {
                @include font-size-base;
                @include copy-treatment;
                font-weight: $font-weight-bold;
            }
        }
    }

    &__links {
        display: flex;
        gap: .5rem;
        letter-spacing: .05em;
        font-weight: $font-weight-bold;
        flex-direction: column;

        @include mq($from:tablet) {
            flex-direction: row;
            gap: 1rem;
        }

        a {
            text-decoration: none;
            transition: color $duration-s $ease;

            &:hover,
            &:focus,
            &:active {
                color: $medium-grey;
            }
        }
    }

    &__charity {
        display: flex;
        flex-direction: column;
    }
}
// Points earned instance component
.twc-loyalty-scheme--points-instance {
  font-family: $font-family-copy;
  width: 200px;
  background-color: #30d9c3;
  padding: 5px;
  font-size: 0.5em;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  @media (min-width: 45em) {
    float: right;
  }
}

// Points cart component
.twc-loyalty-scheme--points-cart {
  font-family: $font-family-copy;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  h3 {
    @include font-size-base-minus-1;
    margin: 7px 0;
    font-family: $font-family-copy !important;
  }
  .points {
    background-color: #30d9c3;
    padding: 2px 20px;
    margin-left: 5px;
    min-width: 70px;
  }
  &.points-earned {
    margin-bottom: 10px;
  }
}

// Points receipt component
.twc-loyalty-scheme--points-receipt {
  width: 100%;
}

// Spend points component
.twc-loyalty-scheme--spend-points {
  width: 100%;
  padding: 20px;
  margin: 20px 0 0;
  background-color: $navy;
  color: $white;
  a {
    margin: 10px 0 0;
    display: block;
  }
}

// Points account card component
.twc-loyalty-scheme--points-account-card {
  width: 100%;
}

// Points terms and conditions component
.twc-loyalty-scheme--points-terms-conditions {
  width: 100%;
}

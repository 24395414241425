// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons
@mixin button-variant($color, $background, $background-hover) {
  color: $color;
  background-color: $background;
  border-color: $background;
  &:hover,
  &:focus,
  &.focus,
  &:active,
  &.active,
  &:active:focus,
  &:active:hover,
  .open > &.dropdown-toggle {
    color: $color;
    background-color: $background-hover;
    border-color: $background-hover;
    box-shadow: none;
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-image: none;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      background-color: $background;
      border-color: $background;
    }
  }
  .badge {
    color: $background;
    background-color: $color;
  }
}

// Button sizes
@mixin button-size($padding-vertical, $padding-horizontal) {
  padding: $padding-vertical $padding-horizontal;
}

.twc-account--access-membership {
  .twc-account--access-membership--card {
    width: 100%;
    background-color: $navy;
    color: #FFF;
    padding: 1rem;
    .access-membership__logo {
      width: 100%;
      margin-bottom: 1rem;
    }
    .access-membership__info {
      legend {
        margin-bottom: 1rem;
        border: none;
        font-size: 1.2rem;
        font-weight: bold;
      }
      label {
        margin-right: 1rem;
      }
      input[type="radio"] {
        margin-right: .5rem;
      }
      p {
        margin: 1rem 0 0;
        max-width: 700px;
      }
    }
    @media (min-width: 45em) {
      padding: 2rem;
      .access-membership__logo {
        width: 80%;
      }
    }
  }
  .twc-account--access-membership--form {
    width: 100%;
    margin: 2rem 0 0;
    .access-membership--form__contact-preference {
      h3 {
        font-size: 1rem;
        text-transform: none;
        letter-spacing: 0;
        font-family: $font-family-copy;
      }
      fieldset {
        padding: 1rem;
        background-color: $light-grey;
        border: 1px solid $black;
        input[type="checkbox"] {
          margin-right: .5rem;
        }
        label {
          margin: 0;
        }
      }
    }
    .access-membership--form__attributes {
      border-top: 3px solid $black;
      margin: 2rem 0 0;
      h3 {
        text-transform: none;
        margin: 0 0 1rem;
      }
      legend {
        border: 0;
        font-size: 1rem;
      }
      .access-membership--form__attribute {
        background-color: $light-grey;
        margin: 0 0 1rem;
        border: 1px solid $black;
        .access-membership--form__attribute-checkbox-group {
          padding: 1rem;
          input[type="checkbox"] {
            margin-right: .5rem;
          }
          label {
            margin: 0;
          }
        }
        .access-membership--form__attribute-description {
          background-color: $white;
          padding: 1rem;
          p {
            padding: 0;
          }
        }
      }
    }
  }
}


.tn-cart-details-page {
    .tn-cart-buttons {
        border: 2px solid $light-grey;
        background-color: $light-grey;
        padding: $spacer-m;
        align-items: center;
        border-radius: 0 0 $border-radius-small $border-radius-small;

        .tn-cart-buttons__primary-action {
            padding-left: $spacer-m;
            padding-right: $spacer-m;
            min-width: 10ch;
            max-width: 100%;

            @include mq($from: desktop) {
                margin-bottom: 0;
            }
        }
    }

    .tn-cart-component:has(.tn-empty-cart-message) ~ .tn-cart-buttons {
        border-radius: $border-radius-small;
    }

    .tn-heading-info {
        background-color: $orange;
        color: $black;
    }
}

.tn-empty-cart-message {
    border: none !important;
    background-color: $light-grey !important;
    color: $black !important;
    border-radius: $border-radius-small;
    font-style: normal !important;

    a {
        @include cta;
    }
}

.tn-cart-component {
    .tn-cart-section {
        margin-bottom: $spacer-l;
    }

    .tn-cart-item {
        border: none;
        padding-bottom: 0;
        margin-bottom: 15px;
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 15px;
        border-radius: $border-radius-small;

        .tn-cart-line-item-name {
            @include heading-treatment;
            @include font-size-base-plus-3;
        }

        .tn-product-type-name {
            font-family: $font-family-copy;
            text-transform: uppercase;
            @include font-size-base-minus-1;
        }

        &.tn-cart-item--package {
            background: transparent;
            padding: 0;

            .tn-cart-item__package-perfs-container {
                border: 0 solid $medium-grey;
                border-left-width: 2px;
                opacity: .75;
            }
        }

        &-summary {
            @include mq($from: tablet) {
                grid-template-columns: 0 auto auto;
            }

            &__property {

                &--price-total {
                    font-weight: bold;
                    @include font-size-base-plus-2;
                }

                &--date-time {
                    font-weight: bold;
                    @include font-size-base;
                    grid-column-start: 1;

                }

                &--image {
                    display: none;
                }
            }
        }
    }

    .tn-cart-item-details__list {
        background-color: transparent;
        padding: 0;
        margin: $spacer-s 0;
        color: $black;
        margin-left: 0 !important;
    }

    .tn-cart-item-detail__list {
        padding: 0;
        border-top: none;
    }

    .tn-cart-item-details__list-item {
        background: transparent;
        border-top: 1px solid $medium-grey;
        padding: $spacer-xs 0;
        margin-bottom: 0;

        &:hover {
            background-color: rgba($medium-grey, 0.15);
        }
    }

    .tn-cart__btn-remove {
        @include btn;
        @include button-variant($btn-secondary-color, $btn-secondary-bg, $btn-secondary-bg-hover);
        @include font-size-base-minus-1;
        @include button-size($padding-small-vertical, $padding-small-horizontal);
        right: 0;

        .tn-cart__btn-remove-icon {
            &::before,
            &::after {
                background-color: currentColor;
            }
        }
    }

    .tn-cart-totals {
        margin: 0;
        border: 1px solid $white;
        border-bottom: none;
        background-color: $light-grey;
        padding: $spacer-m;
        border-bottom: none;
        border-radius: $border-radius-small $border-radius-small 0 0;

        &__line-item {
            @include font-size-base;
            color: $black;
            gap: $spacer-s;
            align-items: baseline;
        }

        &__label {
            font-family: $font-family-copy;
            @include font-size-base-minus-1;
            font-weight: bold;

            &--total {
                @include fluid-type(24px, 28px);
                font-weight: bold;
                color: $black;
            }
        }

        &__value {
            width: auto;

            &--total {
                @include fluid-type(24px, 28px);
                font-weight: bold;
                color: $black;
            }
        }

    }

    &:has(.tn-empty-cart-message) {
        .tn-cart-buttons {
            border-radius: $border-radius-small;
        }
    }
}


.tn-cart-buttons__secondary-action {
	@include btn-to-link;
    @include cta-reverse;
    margin: 15px 0;
}

.tn-customisation-gift-aid {
  img {
    display: block;
    max-width: 150px;
    margin: 15px 0 0;
  }
}

.twc-upsell-donation {
  width: 100%;
  overflow: hidden;
  border: 1px solid $orange;
	background-color: $orange;
  color: $black;
  align-items: center;
  margin: 1rem 0;
  img {
    width: 100%;
  }
  .twc-upsell-donation__form {
    padding: 24px 24px 0;
    display: grid;
    grid-template-columns: 1fr;
    h2 {
      font-size: 2.5rem;
      margin: 0;
    }
    p {
      margin: .75rem 0 1.25rem;
    }
    .donation-selectors {
      display: flex;
      flex-wrap: wrap;
      column-gap: 1rem;
      row-gap: 1rem;
      padding: 0 0 1rem;
    }
    .donation-selectors__item {
      flex: 1;
      border: 1px solid $black;
      background: transparent;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      gap: 1.5rem;
      padding: .65625rem 1rem;
      margin: 0;
      font-weight: 700;
      text-transform: uppercase;
      font-family: $font-family-heading;
      &.button--selected {
        background-color: $black;
        color: $white;
        border: 1px solid $black;
        transition: background .2s ease-in-out, padding .2s ease-in-out;
      }
      &:hover,
      &:focus {
        background-color: $black;
        color: $white;
      }
    }
    #donation-0 {
      margin: 0 0 1rem;
    }
    .custom-donation {
      grid-row: 5;
      grid-column: 1 / 1 span;
      .custom-donation__wrap {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: flex-end;
        row-gap: 1;
        margin: 0 0 1rem;
        .custom-donation__input {
          width: 100%;
          label {
            display: block;
            font-weight: 600;
            font-size: 1rem;
            line-height: 1.1875rem;
            letter-spacing: -.16px;
            margin-bottom: 0;
          }
          input {
            appearance: none;
            background: $white;
            border: 1px solid $black;
            border-radius: 4px;
            padding: .75rem 1rem;
            line-height: 1.1875rem;
            letter-spacing: -.16px;
            margin-top: .5rem;
            width: 100%;
            font-size: 1rem;
          }
        }
        .btn {
          width: 100%;
          margin-left: 1rem;
          display: block;
        }
      }
    }
  }
}

.twc-icon {
  color: $black !important;
  fill: $black !important;
  .cls-1 {
    fill: #fd0 !important;
    color: $black !important;
  }
}

.tn-syos-seat-map__svg {
  .cls-1 {
    fill: #fd0 !important;
    color: $black !important;
  }
}

.twc-legend-group {
  .twc-legend-group-description {
    margin-top: 0;
  }
  svg {
    width: 20px;
    margin: 2px 10px 0 0;
  }
  .cls-1 {
    fill: #fd0 !important;
    color: $black !important;
  }
}

.twc-seat-reduced-opacity {
  opacity: 0.3;
}

.access-icons-only {
  background-color: #FFF;
  margin: -10px -10px 15px;
  border: 1px solid $black;
  padding: .5rem;
  display: flex;
  input[type="checkbox"] {
    margin-right: .5rem;
  }
  label {
    margin: 0;
  }
}

.twc-account--ov-together-membership {
  .twc-account--ov-together-membership--card {
    width: 100%;
    background-color: $navy;
    color: #FFF;
    padding: 1rem;
    &.member {
      display: block;
      flex-direction: row;
      .twc-card-left {
        flex: 2;
      }
      .twc-card-right {
        flex: 1;
      }
      .ov-together-membership__logo {
        width: 100%;
      }
      .qrcode {
        max-width: 160px;
        padding: 10px;
        background-color: $white;
      }
      @media (min-width: 45em) {
        display: flex;
        h2 {
          margin: 0 -25px;
        }
        button {
          font-size: 1rem;
        }
        .qrcode {
          float: right;
        }
        .ov-together-membership__title {
          font-size: 5rem;
        }
      }
    }
    .ov-together-membership__info {
      legend {
        margin-bottom: 1rem;
        border: none;
        font-size: 1.2rem;
        font-weight: bold;
      }
      label {
        margin-right: 1rem;
      }
      input[type="radio"] {
        margin-right: .5rem;
      }
      p {
        margin: 1rem 0 0;
        max-width: 700px;
      }
    }
    @media (min-width: 45em) {
      padding: 2rem;
      .ov-together-membership__logo {
        width: 80%;
      }
    }
  }
  .twc-account--ov-together-membership-benefits {
    margin-top: 2rem;
    h4 {
      font-size: 1.2rem;
      text-transform: none;
      letter-spacing: 0;
      font-family: $font-family-copy;
    }
    .ov-together-membership-benefits-list {
      width: 100%;
      display: flex;
      flex-direction: row;
      list-style: none;
      padding: 0;
      margin: 1rem 0;
      flex-wrap: wrap;
      li {
        flex: 1;
        background-color: $gray-lighter;
        padding: .5rem 1rem;
        margin: 0 0 1rem 0;
        flex-basis: 40%;
        &:nth-child(odd) {
          margin: 0 1.5rem 1rem 0;
        }
        &::before {
          font-family: "FontAwesome";
          content: "\F058";
          margin-right: .5rem;
          font-size: 1.4rem;
        }
      }
    }
    .benefits,
    .terms {
      display: block;
      font-size: 1.2rem;
      text-transform: none;
      letter-spacing: 0;
      font-family: $font-family-copy;
      margin: 1rem 0;
    }
  }
}

.twc-events-performance-information {
  .btn-primary {
    width: 100%;
    text-align: center;
    display: block;
    margin: 10px 0 0;
  }
  h2 {
    font-size: .8rem;
  }
}

//
// Buttons
// --------------------------------------------------
// Base styles
// --------------------------------------------------

@mixin btn {
  @include font-size-base;
  @include heading-treatment;
  display: inline-flex;
  align-items: center;
  margin-bottom: 0; // For input.btn
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: 1px solid transparent;
  white-space: nowrap;
  border-radius: $border-radius-base;
  text-decoration: none;
  @include button-size(10px, 16px);
  @include user-select(none);
  transition: background-color $duration-s $ease, 
    padding $duration-s $ease;
  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      @include tab-focus;
    }
  }
  &:hover,
  &:focus,
  &.focus {
    color: $btn-default-color;
    text-decoration: none;
  }
  &:active,
  &.active {
    outline: 0;
    background-image: none;
    @include box-shadow(inset 0 3px 5px rgba(0, 0, 0, .125));
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    cursor: $cursor-disabled;
    pointer-events: none; // Future-proof disabling of clicks
    @include opacity(.55);
    @include box-shadow(none);
  }
}

@mixin btn-reset {
    color: currentColor;
    background: none;
    border: none;
    border-radius: 0;
    padding: 0;
    text-transform: none;
    width: auto;

    &:hover,
    &:active,
    &:focus {
      background: none;
    }
}

@mixin cta {
    display: inline-flex;
    align-items: center;
    font-weight: $btn-font-weight;
    text-decoration: none;

    &::before,
    &::after {
        font-family: "FontAwesome";
        font-size: .7em;
        line-height: $line-height-base;
        transition: width $duration-s $ease, margin $duration-s $ease;
        overflow: hidden;
    }

    &::after {
        content: "\F061";
        width: 1em;
        margin-left: .6em;
    }

    &:hover,
    &:active,
    &:focus {
      text-decoration: none;

      // TODO
    }
}

@mixin cta-reverse {
    &::before {
        content: "\F060";
        width: 1em;
        margin-right: .6em;
    }

    &::after {
        content: none;
    }

    &:hover,
    &:active,
    &:focus {
      // TODO
    }
}

@mixin btn-to-link {
    @include btn-reset;
    @include cta;
}

.btn {
    @include btn;
}

// Alternate buttons
// --------------------------------------------------
.btn-default {
  @include button-variant($btn-default-color, $btn-default-bg, $btn-default-bg-hover);
}

.btn-primary {
  @include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-bg-hover);
}

.btn-secondary {
  @include button-variant($btn-secondary-color, $btn-secondary-bg, $btn-secondary-bg-hover);
}

.btn-tertiary {
  @include button-variant($btn-tertiary-color, $btn-tertiary-bg, $btn-tertiary-bg-hover);
}

// Success appears as green
.btn-success {
  @include button-variant($btn-success-color, $btn-success-bg, $btn-success-bg-hover);
}

// Info appears as blue-green
.btn-info {
  @include button-variant($btn-info-color, $btn-info-bg, $btn-info-bg-hover);
}

// Warning appears as orange
.btn-warning {
  @include button-variant($btn-warning-color, $btn-warning-bg, $btn-warning-bg-hover);
}

// Danger and error appear as red
.btn-danger {
  @include button-variant($btn-danger-color, $btn-danger-bg, $btn-danger-bg-hover);
}

// Button when it's just an icon, reset the font back to iconfont

.btn.glyphicon {
  font-family: Glyphicons Halflings;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
}

// Link buttons
// -------------------------
// Make a button look and behave like a link
.btn-link {
  color: $link-color;
  font-weight: normal;
  border-radius: 0;
  &,
  &:active,
  &.active,
  &[disabled],
  fieldset[disabled] & {
    background-color: transparent;
    @include box-shadow(none);
  }
  &,
  &:hover,
  &:focus,
  &:active {
    border-color: transparent;
  }
  &:hover,
  &:focus {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
    background-color: transparent;
  }
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus {
      color: $btn-link-disabled-color;
      text-decoration: none;
    }
  }
}

// Button Sizes
// --------------------------------------------------
.btn-lg {
  @include button-size($padding-large-vertical, $padding-large-horizontal);
}

.btn-sm {
  @include font-size-base-minus-1;
  @include button-size($padding-small-vertical, $padding-small-horizontal);
}

.btn-xs {
  @include font-size-base-minus-2;
  @include button-size($padding-xs-vertical, $padding-xs-horizontal);
}

// Block button
// --------------------------------------------------
.btn-block {
  display: block;
  width: 100%;
  max-width: 400px;
}

// Vertically space out multiple block buttons
.btn-block + .btn-block {
  margin-top: 5px;
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.btn-block {
    width: 100%;
  }
}

.twc-member-login-bar {
  position: absolute;
  left: 0;
  width: 100%;
  overflow: hidden;
	background-color: $blue;
  color: $white;
  padding: $nav-link-padding;
  height: 53px;
  margin-top: -$spacer-l;
  p {
    max-width: 300px;
    line-height: 1.2;
  }
  a {
    font-weight: bold;
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
  .btn {
    position: absolute;
    top: 0;
    right: 0;
    background: none;
    height: 42px;
    svg {
      fill: $white;
    }
  }
  @media (min-width: 45em) {
    height: 42px;
    p {
      max-width: 100%;
      line-height: 1.55;
    }
  }
}

@mixin heading-treatment {
    font-family: $headings-font-family;
    line-height: $headings-line-height !important;
    font-weight: $headings-font-weight;
    color: $headings-color;
    text-transform: uppercase;
    letter-spacing: .05em;
}

@mixin copy-treatment {
    font-family: $font-family-copy;
    text-transform: none;
    letter-spacing: normal;
}
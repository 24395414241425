.tn-payment-page {
    max-width: 1000px;
    margin: 0 auto;

    #tn-page-heading {
        @include font-size-base-plus-4;
    }

    .tn-heading-info {
        background-color: $orange;
        color: $black;
    }

    .tn-cart-component,
    .tn-gift-certificates-redemption-component,
    .tn-payment-component {
        margin-top: $spacer-m;

        @include mq($from: desktop) {
            margin-top: $spacer-ml;
        }
    }
}

.tn-component-contact-permissions {
    .btn-dismiss {
        @include btn();
        @include button-variant($btn-secondary-color, $btn-secondary-bg, $btn-secondary-bg-hover);
    }
    .btn-save {
        @include btn();
        @include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-bg-hover);
    }
}


.tn-customisation-gift-aid {
    padding: $spacer-s;
    border-radius: $border-radius-small;
    border: 1px solid $light-grey;
    margin-bottom: $spacer-m;

    @include mq($from: desktop) {
        padding: $spacer-m;
    }

    .tn-heading {
        @include font-size-base-plus-4;
    }

    .tn-cart-buttons {
        align-items: center !important;
        align-items: baseline !important;
    }

    .tn-cart-buttons__item-container {
        display: flex;
        flex-direction: column;
        gap: $spacer-xs;
        align-items: center;

        span {
            @include font-size-base-minus-1;
        }
    }

    .tn-cart-buttons__primary-action {
        margin-bottom: 0;
    }
}

.tn-donation-ask-component {
    padding: $spacer-s;
    border-radius: $border-radius-small;
    background: $dark-grey;
    margin-bottom: $spacer-m;

    @include mq($from: desktop) {
        padding: $spacer-m;
    }

    #tn-add-donation-form {
        margin-bottom: 0;
    }
}

.tn-edit-billing-link,
.tn-edit-delivery-link {
    @include cta;
    margin-top: $spacer-s;
}

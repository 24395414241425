.tn-events-detail-page {
    .tn-prod-season-header {
        padding-bottom: 0;
        border-bottom: none;

        &__title {
            @include font-size-base-plus-4;
        }

        &__date-container {
            font-weight: $font-weight-semi-bold;
        }

        &__description-container {
            background-color: $light-grey;
            padding: $spacer-s;
            border-radius: $border-radius-small;
        }
    }

    .tn-event-details {
        @include mq($from: desktop){
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: $spacer-m;
            .tn-event-detail__additional-events-container {
                width: 100%;
                max-width: 325px;
                font-size: 90%;
                margin: 20px 0 0 0;
                padding: 0;
                .tn-additional-events__btn-submit {
                  background-color: $black !important;
                  color: $white !important;
                }
            }
            .tn-event-detail__performance-details-container {
                max-width: calc(100% - 355px);
            }
        }
    }



    .tn-additional-events__instructions {
        background-color: transparent;
        border: none;
        font-weight: normal;
        padding: 0;
        margin: 0;
        margin-bottom: $spacer-s;
        p > strong {
          font-size: 1.2em;
        }
    }

    .tn-ticket-selector {
        padding: $spacer-s;
        background-color: $white;
        border-radius: $border-radius-large;
        border: 1px solid $medium-grey;

        @include mq($from:desktop) {
            padding: $spacer-m;
        }

        &__heading-available-zones {
            @include font-size-base-plus-1;
        }

        &__pricetype-name {
            @include font-size-base;
        }

        &__pricetype-list-item {
            border-top-color: $medium-grey;
        }

        &__pricetype-placeholder {
            background-color: transparent;
            border: none;
            color: currentColor;
            padding: 0;
            font-weight: $font-weight-bold;
            text-align: left;
        }

        .tn-limited-availability {
            background: $orange;
            padding: $spacer-xs;
            font-size: .65rem;
            text-transform: uppercase;
            font-weight: $font-weight-bold;
            font-style: normal;
            line-height: 1;
            vertical-align: middle;
            border-radius: $border-radius-tiny;
        }

        label:has([data-tn-zone-available-count="0"]) {
            opacity: .65;
            cursor: not-allowed;
        }
    }

    .tn-event-detail {
        flex-wrap: wrap;
        position: relative;
        flex-direction: column;

        &__main-container {
            width: 100%;
        }

        // Date / time of the event
        &__display-time {
            @include font-size-base-plus-1;
            font-weight: bold;
        }
        &__location {
            @include font-size-base-plus-1;
            font-weight: bold;
        }
        &__title {
            display: block;
            @include font-size-base-plus-3;
            @include heading-treatment;
        }
        .tn-product-type-name {
            font-family: $font-family-copy;
            text-transform: uppercase;
            @include font-size-base-minus-1;
        }

        &__unavailable-text {
            background: $orange;
            color: $black;
            padding: $spacer-xs $spacer-s;
            font-size: .75rem;
            text-transform: uppercase;
            font-weight: $font-weight-bold;
            font-style: normal;
            line-height: 1;
            vertical-align: middle;
            border-radius: $border-radius-tiny;
            display: inline;
        }

        //SYOS wrapper
        &__main-container {
            margin-bottom: $spacer-m;
        }

        &__additional-events-container {
            background-color: transparent;
        }

        .tn-additional-events__date-select {
            border-right: none;
        }

        .tn-additional-events__btn-submit {
            @include button-variant($btn-tertiary-color, $btn-tertiary-bg, $btn-tertiary-bg-hover);
            border-radius: 0 $border-radius-small $border-radius-small 0;
        }
    }

    .tn-ticketing-mode-change__anchor {
        @include btn;
        @include button-variant($btn-tertiary-color, $btn-tertiary-bg, $btn-tertiary-bg-hover);

        &--back {
            @include btn-to-link;
            @include cta-reverse;
        }
    }

    .tn-ticketing-mode-change__content {
        padding: 0;
        background-color: transparent;
        border: none;
        font-size: 1rem;
        font-style: normal;
    }
}

.tn-additional-events__continue-shopping-link {
	@include btn-to-link;
    @include cta-reverse;
    margin: 15px 0;
}

.c-header {
	background-color: $black;
	color: $white;

	&__container {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		gap: $spacer-ml;
	}

	&__logo {
		display: block;

		&--mobile {
			@include mq($from: desktop) {
				display: none;
			}
			img {
				width: 66px;
			}
		}

		&--desktop {
			@include mq($until: desktop) {
				display: none;
			}
			img {
				width: 280px;
			}
		}
	}

	&__list {
		margin: 0;
		padding: 0;
		list-style-type: none;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-end;
		gap: $spacer-xs $spacer-m;

		&-item {
			margin: 0;
			padding: 0;
		}

		&-link {
			@include font-size-base-minus-1;
			@include heading-treatment;
			text-decoration: none;
		}
	}
}
.tn-events-listing-page {
    .tn-events-keyword-filter {
        border: none;
        padding: $padding-base-horizontal;
        background: $light-grey;
        margin-bottom: $padding-large-horizontal;
        margin-top: $padding-large-vertical;
        border-radius: $border-radius-small;
    }

    .tn-event-listing__primary-view {
        background-color: transparent;
    }
}

.tn-event-listing-view {
    &__no-results-content {

    }
}
.twc-account--ov-connect-form {
  width: 100%;
  margin: 2rem 0 0;
  .ov-connect-form__interests {
    border-top: 3px solid $black;
    margin: 2rem 0 0;
    legend {
      border: 0;
      font-size: 1rem;
      font-weight: bold;
    }
    .ov-connect-form--form__interest {
      background-color: $light-grey;
      margin: 0 0 1rem;
      border: 1px solid $black;
      .ov-connect-form--form__interest-checkbox-group {
        padding: 1rem;
        input[type="checkbox"] {
          margin-right: .5rem;
        }
        label {
          margin: 0;
        }
      }
    }
  }
  .ov-connect-form__date-of-birth {
    margin: 1rem 0 0;
    legend {
      border: 0;
      font-size: 1rem;
      font-weight: bold;
    }
    .date-of-birth {
      display: flex;
      .form-group {
        flex: 0 0 70px;
        margin-right: 1rem;
        input[type="text"] {
          display: block;
          padding: .5rem;
        }
        label {
          display: block;
        }
      }
    }
  }
  .ov-connect-form__contact-preferences {
    border-top: 3px solid $black;
    margin: 2rem 0 0;
    legend {
      border: 0;
      font-size: 1rem;
      font-weight: bold;
    }
    .ov-connect-form--form__contact-preference {
      background-color: $light-grey;
      margin: 0 0 1rem;
      border: 1px solid $black;
      .ov-connect-form--form__contact-preference-checkbox-group {
        padding: 1rem;
        display: flex;
        input[type="checkbox"] {
          margin-right: .5rem;
        }
        label {
          margin: 0;
          max-width: 700px;
        }
      }
    }
  }
  button {
    margin: 1rem 0;
  }
}

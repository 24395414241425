
.twc-component {
  .c-modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(26, 25, 25, .65);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5000
  }

  .c-modal {
    width: 500px;
    max-width: calc(100% - 14px);
    max-height: calc(100% - 14px);
    background: #fafafa;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    border: 1px solid #ddd;
    z-index: 1000;
    box-shadow: 0 3px 12px 0 rgba(26, 25, 25, .4);
  }

  .c-modal--small {
    width: 440px
  }

  .c-modal--large {
    width: 620px;
    min-height: calc(100% - 14px)
  }

  .c-modal--large .c-modal__body>:last-child {
    margin-bottom: 30px
  }

  .c-modal .c-modal__header {
    background: #fafafa;
    border-bottom: 1px solid #ddd;
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
  }

  .c-modal .c-modal__title {
    display: flex;
    flex-direction: row;
    flex-grow: 2;
    flex-wrap: wrap;
    align-items: center;
    padding-right: 8px;
  }

  .c-modal .c-modal__title h2 {
    margin: .5em 0;
    line-height: 1;
  }

  .c-modal .c-modal__title .seat-location {
    padding-right: .5em
  }

  .c-modal .c-modal__title .price-indicator {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin: 0;
    border-radius: 12px
  }

  .c-modal .c-modal__title .seat-number {
    display: inline-block;
    vertical-align: middle;
    font-weight: 400
  }

  .c-modal .c-modal__close {
    align-self: flex-start
  }

  .c-modal .c-modal__body {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    position: relative;
    background: #fff;
    padding: 10px;
  }

  .c-modal .c-modal__wrap {
    height: 100%;
    display: block;
    position: relative
  }

  .c-modal .c-modal__info {
    background: #fafafa;
    border-bottom: 1px solid #ddd;
    padding: 8px;
    font-size: .75em
  }

  @media (min-width: 45em) {
    .c-modal .c-modal__info {
      font-size: .875em
    }
  }

  .c-modal .c-modal-row {
    padding: 8px;
    display: flex;
    flex-direction: column;
    background-color: #fff
  }

  .c-modal .c-modal-row+.c-modal-row {
    border-top: 1px solid #ddd
  }

  .c-modal .c-modal-row .c-form {
    padding-top: 12px
  }

  .c-modal .c-modal__textarea {
    padding: .25em
  }

  .c-modal .c-modal__textarea ul {
    padding: 0 0 1em 1.5em;
    font-size: .937125em
  }

  .c-modal .c-modal__actions {
    padding: 8px;
    display: flex;
    flex-direction: row
  }

  .c-modal .c-modal__actions .c-btn {
    flex-basis: calc(50% - 6px);
    flex-grow: 2
  }

  .c-modal .c-modal__actions .c-btn:nth-child(2n) {
    margin-left: 12px
  }

  .c-modal .c-modal__actions .c-btn:not(:first-child):not(:nth-child(2)) {
    margin-top: 8px
  }

  .c-modal-event-header {
    border-bottom: 1px solid #efefef;
    padding: .25em 0 .5em;
    margin-bottom: 16px
  }

  .c-modal-event-header .c-modal-event-header__title {
    font-weight: 700;
    font-size: .875em;
    line-height: 1.2;
    padding-bottom: .2em
  }

  @media (min-width: 45em) {
    .c-modal-event-header .c-modal-event-header__title {
      font-size: .937125em
    }
  }

  @media (min-width: 62.5em) {
    .c-modal-event-header .c-modal-event-header__title {
      font-size: 1.06225em
    }
  }

  .c-modal-event-header .c-modal-event-header__meta {
    line-height: 1.1
  }

  .c-modal-event-header time {
    line-height: 1.33;
    font-size: .8125em;
    color: #332d4d;
    font-weight: 500
  }

  @media (min-width: 62.5em) {
    .c-modal-event-header time {
      font-size: .8701875em
    }
  }

  .c-modal-event-header .c-modal-event-header__time {
    white-space: nowrap
  }

  .c-modal-event-header .c-modal-event-header__date:after {
    content: " \2022";
    color: #1a1919
  }

  .c-modal-table {
    display: flex;
    flex-direction: column
  }

  .c-modal-table .c-modal-table__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: .35em
  }

  .c-modal-table .c-modal-table__row+.c-modal-table__row {
    padding-top: .35em;
    border-top: 1px solid #ddd
  }

  .c-modal-table .c-modal-table__row:last-of-type {
    padding-bottom: 0
  }

  .c-modal-table .c-modal-table__details {
    flex-grow: 2;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 16px;
    line-height: 1.1;
    font-weight: 500;
    font-size: .875em
  }

  @media (min-width: 45em) {
    .c-modal-table .c-modal-table__details {
      font-size: .913em
    }
  }

  .c-modal-table .c-modal-table__details .type {
    padding: .2em .5em .2em 0
  }

  .c-modal-table .c-modal-table__details .helper-text {
    font-weight: 400;
    line-height: 1.2;
    display: block;
    padding-top: .35em;
    font-size: .75rem
  }

  @media (min-width: 45em) {
    .c-modal-table .c-modal-table__details .helper-text {
      font-size: .875rem
    }
  }

  .c-modal-table .c-modal-table__details .price {
    white-space: nowrap
  }

  .c-modal-table .c-modal-table__btn {
    min-height: 36px;
    flex-shrink: 0
  }

  .c-modal__footer {
    width: 100%;
    height: 100%;
  }

  .c-modal__footer .c-selection-footer__btn {
    max-width: 200px;
    min-height: 40px
  }

  .c-vfs .c-vfs__image-wrap {
    width: 100%;
    height: auto;
    padding: 2px;
    background-color: #f2f2f2
  }

  .c-vfs .c-vfs__image {
    width: 100%;
    height: auto
  }

  .c-vfs .c-vfs__placeholder {
    width: 100%;
    height: 300px;
    text-align: center;
    background-color: #f2f2f2;
    border-bottom: 1px solid #ddd
  }

  .c-vfs .c-vfs__placeholder img {
    width: 25%;
    padding: 50px 0 0
  }

  .modal-fade-enter,
  .modal-fade-leave-to {
    opacity: 0
  }

  .modal-fade-enter-active,
  .modal-fade-leave-active {
    transition: opacity .2s ease-in-out
  }

  @media (prefers-reduced-motion:reduce) {

    .modal-fade-enter-active,
    .modal-fade-leave-active {
      transition-duration: 0ms !important
    }
  }

  .c-btn--close {
    padding-right: 2em;
    position: relative;
  }

  .c-btn--close:after,
  .c-btn--close:before {
    content: "";
    position: absolute;
    width: 2em;
    font-size: .9em;
    line-height: 1;
    top: -2px;
    height: calc(100% + 4px);
    font-weight: 700
  }

  .c-btn--close:after {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: "FontAwesome"
  }

  .c-btn--close:before {
    opacity: .25;
    transition: border-color .2s ease-in-out, opacity .2s ease-in-out
  }

  .c-btn--close:focus:before,
  .c-btn--close:hover:before {
    border-color: #fff !important;
    opacity: .2
  }

  .c-btn--close:after {
    content: "\F00D";
    right: .2em
  }
}

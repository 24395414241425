.tn-fixed-package-detail-page {
    .tn-package-detail__perf-list {
        background-color: transparent;
    }

    .tn-package-perf {
        padding: $spacer-s 0;
        border: none;

        & + .tn-package-perf {
            border-top: 1px solid $medium-grey;
        }
    }

    .tn-package-perf__property {
        padding: 0;
    }

    .tn-package-perf__property--datetime {
        background: transparent;
        color: $black;
        font-weight: bold;
    }
}
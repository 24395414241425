///* ========================================================================
//   Calculate font size for screens between two breakpoints - fluid type
//   https://css-tricks.com/snippets/css/fluid-typography/
//
//   Uses inuit-font-size as a fallback to output rules for screens outside the given breakpoints
//   ======================================================================== */

@mixin fluid-type($min-font-size, $max-font-size: $min-font-size, $line-height: auto, $min-vw: $tablet, $max-vw: $wide ) {
    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-font-size);
    $u4: unit($max-font-size);

    @if $u1 ==$u2 and $u1 ==$u3 and $u1 ==$u4 {
        & {
            @include inuit-font-size($min-font-size, $line-height);

            @if $min-font-size !=$max-font-size {
                @media screen and (min-width: $max-vw) {
                    @include inuit-font-size($max-font-size, $line-height);
                }

                @media screen and (min-width: $min-vw) and (max-width: $max-vw) {
                    // Use rems for fluid type calcuations
                    $min-font-size: to-rem($min-font-size);
                    $max-font-size: to-rem($max-font-size);
                    $min-vw: to-rem($min-vw);
                    $max-vw: to-rem($max-vw);

                    font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
                }
            }
        }
    }
}


@mixin font-size-base-minus-2 {
    @include fluid-type(12px, 12px, 1.55);
}

@mixin font-size-base-minus-1 {
    @include fluid-type(14px, 14px, 1.55);
}

//h5, copy
@mixin font-size-base {
    @include fluid-type(16px, 16px, 1.55);
}

//h4
@mixin font-size-base-plus-1 {
    @include fluid-type(18px, 22px, 1.55);
}

// h3
@mixin font-size-base-plus-2 {
    @include fluid-type(22px, 24px, 1.45);
}

// h2
@mixin font-size-base-plus-3 {
    @include fluid-type(24px, 28px, 1.35);
}

// h1
@mixin font-size-base-plus-4 {
    @include fluid-type(30px, 34px, 1.15);
}
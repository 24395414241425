.page-wrap {
    background-color: $page-background;

    > .content-wrap {
        padding-top: 0;
    }
}

.content-wrap {
    padding: $spacer-s;

    @include mq($from: tablet) {
        padding: $spacer-m;
    }

    @include mq($from: desktop) {
        padding: $spacer-ml;
    }
}

.page-wrap .content-wrap {
    padding-bottom: 50px;
    min-height: 90vh;
}

.max-width {
    max-width: $max-site-width;
    margin: 0 auto;
}

/*
 * TNEW subnavigation
 * Contains the login/register/basket links, promo code field
 */

 .tn-subnav-component {
    position: relative;
    padding: $spacer-s 0;
    color: $white;
    margin-left: 0;
    margin-bottom: $spacer-l;
    flex-wrap: wrap;
    gap: $spacer-s;

    @include mq($from: $desktop) {
        gap: $spacer-m;
    }

    // making sure the nav contents sit on top of the bg
    & > div {
        position: relative;
        z-index: 2;
    }

    // Grey background for the secondary navigation
    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        width: 100vw;
        bottom: 0;
        display: block;
        z-index: 0;
        transform: translateX(-50%);
        background-color: $grey;
    }

    a {
        @include font-size-base-plus-1;
        text-decoration: none;

        @include mq($from: desktop) {
            @include font-size-base-plus-2;
        }

        .fa {
            font-size: 1.75em;

            @include mq($from: desktop) {
                font-size: 1em;
            }
        }
    }

    // Timer
    .tn-cart-link .tn-timer {
        @include font-size-base-minus-1;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        padding: 1rem;
        z-index: 2;
        text-align: center;

        &::before {
            content: "\f017";
            font-family: 'FontAwesome';
        }

        &,
        &.tn-timer-urgent {
            background: $blue;
            color: $white;
            font-weight: $font-weight-regular;
            margin: 0;
        }

        .tn-count-down-timer {
            font-weight: $font-weight-bold;
        }
    }

    // A lot of selectors to override the tnew madness
    // Resulting selectors target the exact same selectors as TNEW
    .tn-promo-box {
        margin-left: 0;
        margin-right: 0;
        z-index: 1;

        &.promo-applied,
        &.tn-promo-applied {
            display: flex;
        }

        &::before {
            display: none !important;
        }

        // Promo code input field
        .tn-text,
        input.tn-subnav-promo-code {
            @include font-size-base-minus-1;
            font-weight: bold;
            min-width: 18ch;
            @include mq($from: tablet) {
                min-width: 25ch;
            }
            border-radius: $input-border-radius 0 0 $input-border-radius;
            text-transform: none;
            text-align: left;
            color: $input-color;
            background-color: $input-bg;
            background-image: none;
            border: 1px solid $input-border;
            padding: $spacer-xs $spacer-s;
            line-height: 2.2 !important;
        }

        #tn-subnav-promo-button {
            @include btn;
            @include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-bg-hover);
            @include font-size-base-minus-1;
            @include button-size(0, $padding-small-horizontal);
            border-radius: 0 $input-border-radius $input-border-radius 0;
        }

        // Promo code submit button
        button.tn-link {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        #tn-apply-promo {
            display: flex !important;
        }
    }

    .form-inline {
        z-index: 1;

        > .tn-text {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
            text-transform: none;
            text-align: left;
            margin-top: 0;
            display: inline-flex;
        }

        > button.btn {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    .tn-login-link {
        display: flex;
        gap: .5em 2.25em;
        flex: 0 1 auto;

        &.tn-logged-in .tn-logout-link {
            display: inline-flex;
        }
    }

    .tn-cart-link {
        margin-left: auto;
        flex: 0 1 auto;

        .tn-icon::after {
            content: "\F290" !important;
        }
    }

    .tn-logged-in-text,
    .tn-logged-in-email,
    .tn-login-link:not(.tn-logged-in) .tn-text,
    .tn-cart-link .tn-text {
        @include hide-visually;
    }

    .tn-logout-link::before {
        content: none !important;
    }

    .tn-logout-link {
        @include btn;
        @include button-variant($white, $grey, $medium-grey);
        @include font-size-base-minus-1;
        @include button-size($padding-small-vertical, $padding-small-horizontal);
    }
}

body:has(.tn-timer[data-tn-is-cart-timed]) {
    margin-top: 3.25rem;
}

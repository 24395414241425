.tn-receipt-page {
    .tn-error-message {
        color: $black;
        background-color: $orange;
        border-color: $orange;
    }
    .tn-heading-sub-text {
        margin-left: auto;
        margin-right: auto;
    }

    .tn-receipt-component {
        .col-lg-3 {
            display: none;
        }

        .row [class*="col"] {
            width: 100% !important;
            right: 0 !important;
            left: 0 !important;
            text-align: center;
        }

        h2 {
            @include hide-visually;
        }
    }

    .tn-additional-information {
        display: none;
    }

    .tn-order-address-information-component {
        .tn-patron-billing-information {
            display: none;
        }

        .row [class*="col"] {
            width: 100% !important;
            right: 0 !important;
            left: 0 !important;
            text-align: center;
        }

        h2 {
            @include hide-visually;
        }
    }
    
    .tn-cart-buttons {
        border: 2px solid $white;
        background-color: $light-grey;
        padding: $spacer-m;
        align-items: center;
        border-radius: 0 0 $border-radius-small $border-radius-small;
    
        .tn-cart-buttons__primary-action {
            padding-left: $spacer-m;
            padding-right: $spacer-m;
            min-width: 10ch;
            max-width: 100%;
    
            @include mq($from: desktop) {
                margin-bottom: 0;
            }
        }
    }
}
.twc-loyalty-scheme--account-card {
  width: 100%;
  background-color: $navy;
  color: #2ed9c3;
  padding: 1rem;
  .loyalty-scheme__logo {
    width: 100%;
  }
  .loyalty-scheme__info {
    color: $white;
    h3 {
      font-size: 1rem;
      margin: 1rem 0;
      .loyalty-scheme__info--points {
        font-size: 3rem;
        display: block;
      }
    }
    .btn {
      margin-top: 1rem;
    }
  }
  .loyalty-scheme__thanks {
    margin: 2rem 0;
    h4 {
      font-size: 1rem;
    }
  }
  .points-receipt-line {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    color: #2ed9c3;
    &:first-of-type {
      margin-top: 2rem;
    }
    .points {
      background-color: #30d9c3;
      padding: 2px 20px;
      margin-left: 5px;
      min-width: 70px;
      color: #000;
      font-family: "akzidenz-grotesk-extended",sans-serif;
    }
  }
  @media (min-width: 45em) {
    padding: 2rem;
    .loyalty-scheme__logo {
      width: 80%;
    }
    .loyalty-scheme__info {
      h3 {
        .loyalty-scheme__info--points {
          display: inline-block;
        }
      }
    }
  }
}

.tn-cart-component {
  .twc-loyalty-scheme--account-card {
    margin-bottom: 20px;
  }
}

.twc-loyalty-scheme--terms-and-conditions {
  width: 100%;
  margin: 1rem 0 0;
  #terms-and-conditions-toggle {
    width: 100%;
    background: none;
    border: none;
    font-size: 1rem;
    padding: 1rem 0;
    text-align: left;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    &:hover,
    &:focus {
      border-bottom: 2px solid $black;
    }
    .accordion__trigger-button {
      align-items: center;
      display: flex;
      flex-direction: row;
      font-weight: 700;
      letter-spacing: .06rem;
      text-transform: uppercase;
      .accordion-icon {
        align-items: center;
        display: flex;
        height: 1.5rem;
        position: relative;
        width: 1.5rem;
        .accordion-icon__line {
          background: #000;
          height: .25rem;
          position: absolute;
          width: 100%;
          &.accordion-icon__line--vertical {
            transform: rotate(90deg);
            transform-origin: center;
            transition: transform .2s ease-in-out;
          }
          &.accordion-icon__line--horizontal {
            background: #000;
            height: .25rem;
            position: absolute;
            width: 100%;
          }
        }
        &.accordion-icon--open {
          .accordion-icon__line--vertical {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
  #terms-and-conditions-text {
    width: 100%;
    margin: 2rem 0 0;
  }
}
